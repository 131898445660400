import React from 'react';

import { SOCIAL_SIGNIN_PROVIDER } from 'utils/constants';
import PublicComponent from 'navigation/PublicComponent';
import SocialSignInLanding from 'features/SocialSignIn/SocialSignInLanding';

interface IFacebookLogin {
  location: Location;
}

export interface ILoginApiResponse {
  access: string;
  refresh: string;
}

const FacebookLogin: React.FC<IFacebookLogin> = ({ location }) => (
  <PublicComponent>
    <SocialSignInLanding location={location} provider={SOCIAL_SIGNIN_PROVIDER.FACEBOOK} />
  </PublicComponent>
);

export default FacebookLogin;
