import React from 'react';

import FullScreenWrapper from 'components/FullScreenWrapper';
import { useUserInfo } from 'state/UserInfoStateProvider';
import { ROUTES } from 'utils/constants';
import { navigateWithQueryParams } from 'utils/helpers';
import { COOKIE_KEY, getCookie } from 'utils/cookies';

interface IPublicComponent {
  children: React.ReactNode;
}

const PublicComponent: React.FC<IPublicComponent> = ({ children }) => {
  const userInfo = useUserInfo();
  const isAuthProcessing = userInfo === null;
  const isUserSignedIn = userInfo?.accessToken && userInfo?.refreshToken && !isAuthProcessing;
  const hasUserSubscription = !!userInfo?.info?.user?.subscription;
  const skipCheckout = getCookie(COOKIE_KEY.SKIP_CHECKOUT) === 'true';

  if (isUserSignedIn && ((hasUserSubscription && !isAuthProcessing) || skipCheckout)) {
    navigateWithQueryParams(ROUTES.PURCHASE_SUCCESS);
  }

  if (isUserSignedIn && !hasUserSubscription && !isAuthProcessing) {
    navigateWithQueryParams(ROUTES.CHECKOUT);
  }

  if (isAuthProcessing) {
    return <FullScreenWrapper />;
  }

  return <>{children}</>;
};

export default PublicComponent;
