import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { AxiosResponse } from 'axios';

import FullScreenWrapper from 'components/FullScreenWrapper';
import { IMarketingProps } from 'features/Register/RegisterForm';
import { getCookie, COOKIE_KEY } from 'utils/cookies';
import { callCoreApi, METHODS, API_URLS } from 'api';
import { useUpdateUserInfo } from 'state/UserInfoStateProvider';
import { useUserAnswersContext, IQuestionAndAnswerApiObject } from 'state/UserAnswersStateProvider';
import {
  SOCIAL_SIGNIN_PROVIDER,
  QUERY_PARAMS,
  GOOGLE_LOGIN,
  FACEBOOK_LOGIN,
  ROUTES,
} from 'utils/constants';

interface ISocialSignInLanding {
  location: Location;
  provider: SOCIAL_SIGNIN_PROVIDER;
}

export interface ILoginApiResponse {
  access: string;
  refresh: string;
}

export interface IAuthCodeSignInProps {
  provider: SOCIAL_SIGNIN_PROVIDER;
  code: string;
  platform: 'web';
  redirect_url: string;
}

const SocialSignInLanding: React.FC<ISocialSignInLanding> = ({ location, provider }) => {
  const updateUserInfo = useUpdateUserInfo();
  const { getFormattedAnswers } = useUserAnswersContext();

  if (window.ga?.getAll && typeof window.ga?.getAll === 'function') {
    window.ga?.getAll()?.[0]?.set('set', 'referrer', document.location.origin);
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const authCode = params.get(QUERY_PARAMS.CODE);

    if (!authCode) {
      navigate(ROUTES.REGISTER);

      return;
    }

    const fbp = getCookie(COOKIE_KEY._FBP);
    const fbc = getCookie(COOKIE_KEY._FBC);
    const appsflyer_web_id = getCookie(COOKIE_KEY.AF_USER_ID);
    const fbclid = getCookie(COOKIE_KEY.FBCLID);
    const gclid = getCookie(COOKIE_KEY.GCLID);
    const google_cid = getCookie(COOKIE_KEY.YOURS_CID);
    const tune_offer_id = getCookie(COOKIE_KEY.TUNE_OFFER_ID);
    const tune_transaction_id = getCookie(COOKIE_KEY.TUNE_TRANSACTION_ID);
    const campaign = getCookie(COOKIE_KEY.UTM_CAMPAIGN);
    const source = getCookie(COOKIE_KEY.UTM_SOURCE);
    const medium = getCookie(COOKIE_KEY.UTM_MEDIUM);
    const impact_click_id = getCookie(COOKIE_KEY.IMPACT_CLICK_ID);

    const redirect_url =
      provider === SOCIAL_SIGNIN_PROVIDER.FACEBOOK ? FACEBOOK_LOGIN : GOOGLE_LOGIN;

    callCoreApi<IAuthCodeSignInProps & IMarketingProps>({
      method: METHODS.POST,
      url: API_URLS.AUTH_CODE_SIGNIN,
      data: {
        provider,
        code: authCode,
        google_cid,
        appsflyer_web_id,
        platform: 'web',
        fbp,
        fbc,
        gclid,
        fbclid,
        redirect_url,
        tune_offer_id,
        tune_transaction_id,
        campaign,
        source,
        medium,
        impact_click_id,
      },
    })
      .then((response: AxiosResponse<ILoginApiResponse>) => {
        updateUserInfo({
          accessToken: response.data.access,
          refreshToken: response.data.refresh,
        });

        const data = getFormattedAnswers();

        if (!data.length) {
          return;
        }

        callCoreApi<IQuestionAndAnswerApiObject[]>({
          method: METHODS.POST,
          url: API_URLS.USER_ANSWERS,
          accessToken: response.data.access,
          data,
        });
      })
      .catch(() => {
        navigate(ROUTES.REGISTER);
      });
  }, []);

  return <FullScreenWrapper />;
};

export default SocialSignInLanding;
